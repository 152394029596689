body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/images/background-2.jpg") center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* background: #3a506b !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.glass {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 30%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.block{
  display: block;
}

.inline-block{
  display: inline-block;
}

.ant-modal-content {
  background: transparent !important;
  box-shadow: none !important;
  
}

.table-row-light .ant-table-cell-fix-left{
  background-color: #ffffff !important;
}
.table-row-dark .ant-table-cell-fix-left{
  background-color: #ededed !important;
}
.table-row-dark:hover td {
  background-color: #eaeaea !important;
}



.table-row-dark td:last-child, .table-row-light td:last-child {
  background: repeating-linear-gradient(90deg, #ffffff 1px, #FFFFFF 48px,  #f1f1f1 49px, #f1f1f1 100px) !important;
  /* pointer-events: none; */
}
/* 
.ant-table-tbody > tr:not(.ant-table-tbody > tr:first-child) > td {
  padding: 10px 16px !important;
} */

.ant-tooltip-inner {
  width: fit-content;
  max-width: 550px !important;
}

#searchResults .ant-table-tbody > tr.ant-table-row:hover > td, #searchResults .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fff !important;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  letter-spacing: 0.4px;
}